<template>
  <v-container id="login" class="fill-height" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          icon="mdi-camera-account"
          title="Please confirm to log out"
          light max-width="100%" width="400" 
          class="py-3 px-5"
        >
          <v-form @submit.prevent="onSubmit">
                       
            <v-card-actions class="pa-0">
              <v-spacer />

              <v-btn
                type="submit"
                large
                color="success"
                depressed
                class="v-btn--text"
              >
                Logout
              </v-btn>
            </v-card-actions>
            <v-snackbar
            v-model="retSnackbar.show"
            :bottom="retSnackbar.y === 'bottom'"
            :color="retSnackbar.color"
            :left="retSnackbar.x === 'left'"
            :multi-line="retSnackbar.mode === 'multi-line'"
            :right="retSnackbar.x === 'right'"
            :timeout="retSnackbar.timeout"
            :top="retSnackbar.y === 'bottom'"
            :vertical="retSnackbar.mode === 'vertical'"
          >
            {{ retSnackbar.text }}
            <v-btn dark text @click="retSnackbar.show = false">Close</v-btn>
          </v-snackbar>
          </v-form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { gexLogout } from "@/views/dashboard/components/core/orarest";

export default {
  name: "PagesLogin",
  components: {
    PagesBtn: () => import("./components/Btn")
  },
  metaInfo: {
    title: "Logout",
    titleTemplate: "Logout Gothar Exercise",
    description: "Logout Gothar Exercise."
  },
  data() {
    return { 
      retSnackbar: {
        show: false,
        color: "error",
        timeout: 8000,
        text: "Logout was not possible. Please try again.",
        mode: "",
        x: null,
        y: "top"
      }
    };
  },
  methods: {
    async onSubmit() {
        console.info("gex rLogoff json: ");
       const response = await gexLogout();
        console.info("gex rLogoff json: ", response);
        if (response.status == 200) {
          this.$router.push("/pages/timeline");
        } else {
          // this.retSnackbar.text = response.retmsg;
          this.retSnackbar.show = true;
        }
        //this.newExcercise = Object.assign({}, initExcercise);
        //this.showLoading = false;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
  }
};
</script>
